import {
    SET_TOASTER
} from '../types'
import {defaultToaster} from '../../constants/defaultValues'

export default (state = defaultToaster, action = {}) => {
    if (action.type === SET_TOASTER) {
        const counter = state.counter
        return {
            ...state,
            ...action.payload,
            counter: counter + 1
        }
    } else {
        return {...state}
    }
}