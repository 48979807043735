import {
    REQUEST_FRONTEND,
    RECEIVE_FRONTEND,
    ERROR_FRONTEND
} from '../types'
import {initialFrontendFetchState} from '../../constants/defaultValues'

export default (state = initialFrontendFetchState, action = {}) => {
    const newState = {...state}
    switch(action.type) {
        case REQUEST_FRONTEND:
            if(action.subType){
                newState[action.dataType][action.subType] = {
                    data: {},
                    isFetching: true
                }
            }else{
                newState[action.dataType].isFetching = true
            }
            return newState
        case RECEIVE_FRONTEND:
            if(action.subType){
                newState[action.dataType][action.subType] = {
                    ...newState[action.dataType][action.subType],
                    isFetching: false,
                    data: action.payload,
                    message: ''
                }
            }else{
                newState[action.dataType] = {
                    ...newState[action.dataType],
                    isFetching: false,
                    data: action.payload,
                    message: ''
                }
            }
            return newState
        case ERROR_FRONTEND:
            if(action.subType){
                newState[action.dataType][action.subType] = {
                    ...newState[action.dataType][action.subType],
                    isFetching: false,
                    data: {},
                    message: 'unexpected_error'
                }
            }else{
                newState[action.dataType] = {
                    ...newState[action.dataType],
                    isFetching: false,
                    data: [],
                    message: 'unexpected_error'
                }
            }
            return newState
        default:
            return {...state}
    }
}