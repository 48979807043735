import {
    REQUEST_SEQUENCE,
    RECEIVE_SEQUENCE,
    ERROR_SEQUENCE
} from '../types'
import {initialSequenceFetchState} from '../../constants/defaultValues'
import _isObject from 'lodash/isObject'

export default (state = initialSequenceFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_SEQUENCE:
            return {
                ...state,
                isFetching: true,
                key: action.payload,
                message: '',
                errors: {}
            }
        case RECEIVE_SEQUENCE:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: action.message,
            }
        case ERROR_SEQUENCE:
            return {
                ...state,
                isFetching: false,
                errors: action.payload,
                message: _isObject(action.payload) && action.payload.server ? action.payload.server : ''
            }
        default:
            return {...state}
    }
}